import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBuilder from "../components/page-builder"
import { SimpleStory } from "@hurleymc/components"

const PagePrivateSimpleStotry = ({ data }) => {
  const { craft } = data
  const { entries } = craft
  const entry = entries[0]
  const image =
    entry.hfImage && entry.hfImage.length > 0 && entry.hfImage[0].lg
      ? entry.hfImage[0].lg
      : null
  return (
    <Layout>
      <SEO title={entry.title} image={image} />
      <SimpleStory image={entry.hfImage} />
      {entry && entry.pageBuilder && (
        <PageBuilder
          pageBuilder={entry.pageBuilder}
          location={{ pathname: entry.uri }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query PagePrivateSimpleStotry($id: [Craft_QueryArgument]!) {
    craft {
      entries(site: "hurleyFoundation", id: $id) {
        __typename
        title
        uri
        ... on Craft_hfPagePrivate_simpleStory_Entry {
          hfImage {
            ... on Craft_AssetInterface {
              title
              url
              lg: url(transform: "splashLg", immediately: true)
              xl: url(transform: "splashXl", immediately: true)
            }
          }
          pageBuilder {
            ...PageBuilder
          }
        }
      }
    }
  }
`

export default PagePrivateSimpleStotry
